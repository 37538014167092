<template>
  <div class="bg-white shadow sm:rounded-lg max-w-3xl m-auto mt-10">
    <component v-if="$route.params.formname" :is="formComponent" />
  </div>
</template>
<script>
/*import { defineAsyncComponent } from '@vue/runtime-core'

export default {
  computed: {
    formComponent() {
      return defineAsyncComponent(
        () => import(`@/pay/${this.$route.params.formname}`)
      )
    }
  }
}*/
</script>
